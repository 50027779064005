import * as React from 'react';
import {RouteComponentProps} from "react-router";
import {InfoBreadcrumb} from "./Data";
import { ActionLink, EditLink, Icons, showConfirmBox, showNotification} from "../../common";
import { ListHeader, ListingLayout} from "../../layout/Listing";
import _, { get } from "lodash";
import { PopupService, QuestionnaireService, SportService } from '../../services';
import { AgeGroupModel, PopupInfoModel, PopupIntroModel, PopupModel, RoleModel, SportModel } from '../../model';
import { PopupInfoModal } from './modal/InfoForm';
import { IntroPopupModal } from './modal/IntroPopup';
import { UserService } from '../../services/UserService';

type IState = {
  id: number;
  popup: PopupModel;
  show: boolean;
  intro_show: boolean;
  active_intro: PopupIntroModel;
  info: PopupInfoModel,
  intro: {
    roles: Array<RoleModel>;
    age_groups: Array<AgeGroupModel>;
    sports: Array<SportModel>
  }
}
export class PopupInfo extends React.Component<RouteComponentProps, IState> {

  
  constructor(props:any){
    super(props);
    this.state = {
      show: false,
      intro_show: false,
      id: props.match.params.id,
      popup: {} as PopupModel,
      info: {} as PopupInfoModel,
      intro: {} as any,
      active_intro: {} as PopupIntroModel,
    }
  }

  init = async () => {
    const popup: PopupModel = await PopupService.getPopupInfo(this.state.id);
    this.setState({popup})
  }
  
  loadIntroInfo = async () => {
    const ageGroupList: Array<AgeGroupModel> = await QuestionnaireService.getAgeGroups();
    const sportsList: Array<SportModel> = await SportService.getList();
    const rolesList: Array<RoleModel> = await UserService.getRoles();
    const intro = {
      age_groups: ageGroupList,
      sports: sportsList,
      roles: rolesList
    }
    this.setState({intro})
  }

  componentDidMount() {
    this.init();
    this.loadIntroInfo();
  }
  
  deletePopupInfo(id: number) {
    const __that = this;
    showConfirmBox('Are you sure that you want to delete this information?', async function() {
      try {
        const response = await PopupService.deletePopupInfo(id);
        showNotification('success', response);
        __that.init();
      } catch (e) {
        console.log(e);
        showNotification('error', 'Somthing went wrong. Please try again')
      }
    });
  }

  render() {
    const { popup } = this.state;
    return (
      <div>
        <div style={{display: 'flex', justifyContent: 'end', marginBottom: 10}}>
          <button style={{marginRight: 30}} onClick={() => this.setState({intro_show: true})} className='btn btn-brand'>Add content for age group and Sport </button>
        </div>
        {popup.intro?.length ===0 && 
           <ListingLayout breadcrumb={InfoBreadcrumb}>
            <div className='alert alert-danger'>No records found</div>
            </ListingLayout>}
        {
          popup.intro?.length > 0 && 
          popup.intro.map((intro: PopupIntroModel, index: number) => {
            return (
              <ListingLayout breadcrumb={InfoBreadcrumb} key={index}>
              <ListHeader title={`Popups content for ${_.get(popup, 'title', '')}`} icon={Icons.contract} 
                data-toggle="collapse" data-target={`#poup_intro_${intro.id}`} aria-expanded="true" aria-controls={`poup_intro_${intro.id}`} className={'pointer'} >
                <span className='badge badge-primary mr-10px'>{ intro.sport.name }</span>
                <span className='badge badge-danger mr-10px'>{ intro.role.name }</span>
                {intro.age_group !== null && <span className='badge badge-warning mr-10px'>{ intro.age_group.title }</span> }
              </ListHeader>
              <div className={`table-responsive collapse ${index == 0 ? 'show': ''}`} id={`poup_intro_${intro.id}`} aria-labelledby="headingOne" >
                <table className={"table table-bordered table-striped"}>
                  <thead className={'kt-datatable__head'}>
                    {
                      intro.info?.length > 0 && 
                      <tr>
                        <th style={{textAlign: 'center'}}>S.N</th>
                        <th>Title</th>
                        <th>Action</th>
                      </tr>
                    }
                    
                  </thead>
                  <tbody>
                    {
                      intro.info?.map((item: PopupInfoModel, index: number ) => (
                        <tr>
                          <td align='center'>{index + 1 }</td>
                          <td>{item.title}</td>
                          <td>
                            <EditLink scope={'popups.info.update'} to={`#`}  title='Info' onClick={() => {
                             this.setState({show: true, info: item})
                            }} />
                            <ActionLink scope={'popup.info.delete'} to='#' title='Delete' onClick={() => this.deletePopupInfo(item.id)} >
                              <i className={Icons.delete}></i>
                              </ActionLink>
                          </td>
                        </tr>
                      ))
                    }
                    {
                      intro.info?.length == 0 && 
                      <tr>
                        <td colSpan={3}> <div className='alert alert-danger'>No records found</div> </td>
                      </tr>
                    }
                    <tr>
                      <td colSpan={3}>
                        <button className='btn btn-primary' onClick={e => this.setState({show: true, active_intro: intro})}>Add + </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </ListingLayout>
            )
          })
        }
     
      {
            popup && 
              <PopupInfoModal
                show={get(this.state, "show", false)}
                onHide={() => {
                  this.setState({show: false, info: undefined, active_intro: undefined});
                  this.init();
                }}
                info={get(this.state, "info", {})}
                popup={popup}
                intro={ this.state.active_intro}
              />
          }
          {
            popup && 
              <IntroPopupModal
                show={get(this.state, "intro_show", false)}
                onHide={() => {
                  this.setState({intro_show: false});
                  this.init();
                }}
                intro={ this.state.intro}
                popup={popup}
              />
          }
      </div>
    )
  }
}
