import * as React from 'react';
import {ResourceModel} from "../../model";
import {IState as CommonState} from "../../common/State";
import {actionButton, ActionLink, CreateLink, ExportButton} from "../../common";
import {RouteComponentProps} from "react-router";
import {ListBreadcrumb} from "./Data";
import { EditLink, ShowRecords, Icons} from "../../common";
import {ListForm, ListHeader, ListingLayout} from "../../layout/Listing";
import _ from "lodash";
import {list_key} from "../../helpers/OrderHelper";
import Filters from "./Filters";
import {humanifyDate, listingStatus} from "../../helpers/UserHelper";
import { ResourceService } from '../../services';

interface IState extends CommonState{
  filters: any;
}

export class Resources extends React.Component<RouteComponentProps, IState> {
  records:React.RefObject<ShowRecords> = React.createRef();
  state: IState;
  componentDidMount() {
    const ___that = this;
    async function  init() {
      const data = await ResourceService.formData();
      ___that.setState({
        filters: data
      })
    }
    init();
  }


  showContent = (data: ResourceModel, index: number) => {
    
    let cols = [];
    cols.push(...[
      <td key={index}>{this.records.current.checkbox(data.id)}</td>,
      this.records.current.createdTd("role_id", data.role.name),
      this.records.current.createdTd("sport_id", data.sport.name),
      this.records.current.createdTd("age_group_id", data.age_group.title),
      this.records.current.createdTd("concept_id", data.concept.name),
      this.records.current.createdTd("title", data.title),
      this.records.current.createdTd("file", () => <a href={data.file} target='_blank' rel="noopener noreferrer">Resource File</a>),
      this.records.current.createdTd("description", data.description),
      this.records.current.createdTd("status", () => listingStatus(data.status)),
      this.records.current.createdTd("created_at", humanifyDate(data.created_at)),
      this.records.current.createdTd("action", () =>  this.rowActionButtons(data))
    ]);
    cols = _.compact(cols);
    return (
      <tr key={list_key(data.id)}>{cols}</tr>
    );
  }

  filterRecords = (data?: any) => {
    if( this.records && this.records.current !== null){
      if(data !== undefined){
        this.records.current.setFormFields(data);
      }else {
        this.records.current.setFormFields();
      }
    }
  }


  rowActionButtons = (data: ResourceModel) => {
    return (
      <div style={{display: 'flex', justifyContent: 'start', width: 20}}>
      <EditLink scope={'resources.update'} to={`${this.props.match.url}/edit/${data.id}`} />
      <ActionLink scope={'resources.create'} to={`${this.props.match.url}/copy/${data.id}`} title="copy" >
        <i className={Icons.copy} />
      </ActionLink>
      </div>
    )
  }

  render() {
    return (
        <ListingLayout breadcrumb={ListBreadcrumb}>
          <ListHeader title={"Resources"} icon={Icons.contract} >
            <ExportButton records={this.records} fileName={"resources"}/>
            &nbsp;
            <CreateLink to={`/quiz/resources/create`} scope={'resources.store'} />
          </ListHeader>

          <ListForm
            form={<Filters getResults={this.filterRecords} {..._.get(this.state, 'filters', {})} />}
            table={(
              <ShowRecords
                url={"/quiz/resources"}
                showRecords={this.showContent}
                ref={this.records}
                isNeedAction={true}
                actionButtons={actionButton({records: this.records, 'url': "quiz/resources/bulk-action", "module": 'sports'})}
                rowActions={this.rowActionButtons}
               />
            )}
          />
        </ListingLayout>
    )
  }
}
